.combined-calendar {
  .cal-week-view .cal-time-events, .cal-time-label-column {
    display: none !important;
  }

  .cal-week-view .cal-day-headers {
    padding-left: 0;
  }

  .cal-week-view .cal-events-row {
    margin-left: 0 !important;
  }

  .material-icons.mat-icon {
    vertical-align: middle;

    &.complete {
      color: #6aacff;
    }

    &.overdue {
      color: #da0000;
    }

    padding-right: 4px;
  }

  .cal-event-container {
    .cal-event-actions {
      margin-right: 4px;
    }

    span.cal-event-title {
      font-size: 16px;
    }

    &.hire-item {
      .cal-event {
        //background: #ffbebe;

        span.cal-event-title {
          color: #003a95;
        }
      }
    }

    &.available {
      .cal-event {
        //background: #3F51B5;

        span.cal-event-title {
          color: #005b1a;
        }
      }
    }

    &.booking-item {
      span.cal-event-title {
        color: #7e0000;
      }
    }
  }

  .cal-week-view .cal-all-day-events .cal-events-row {
    height: 40px;

    .cal-event {
      height: 38px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

delivery-list {
.cal-event-container {
  &.available {
    .cal-event {
      span.cal-event-title {
        color: #005b1a;
      }
    }
  }
}
}