
div.ag-header-cell {
  font-weight: normal !important;
  padding-top: 8px !important;
}


.ag-row-odd {
  background-color: #f3f3f3 !important;
}

.ag-material .ag-body-container .ag-row-hover {
  background-color: $lighter-green !important;
}

.ag-theme-alpine .ag-body-container .ag-row-selected {
  background-color: #e8f5ad !important;
}

.hov-pointer:hover {
  cursor: pointer;
}

.ag-cell, .ag-header-cell {
  font-size: 18px;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-no-focus.ag-cell-value {
  outline: none;
}

.ag-filter-filter {
  width: initial;
}

.ag-theme-alpine .ag-header-cell::after, .ag-theme-alpine .ag-header-group-cell::after {
  height: 32px !important;
}

div.ag-root {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
