@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

$primary: mat.m2-define-palette(mat.$m2-teal-palette, 800);
$accent: mat.m2-define-palette(mat.$m2-cyan-palette, 600, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 600);
$theme: mat.m2-define-light-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);


// body.geocal-ltd {
//   $dark-primary-text: rgba(black, 0.87);
//   $light-primary-text: white;
//   $palette: (
//     50: #e3f0f4,
//     100: #badae7,
//     200: #92c2d7,
//     300: #6dabc8,
//     400: #529abe,
//     500: #3a8bb6,
//     600: #2f7eab,
//     700: #226e9a,
//     800: #195e89,
//     900: #0a426a,
//     contrast: (
//       50: $dark-primary-text,
//       100: $dark-primary-text,
//       200: $dark-primary-text,
//       300: $dark-primary-text,
//       400: $dark-primary-text,
//       500: $light-primary-text,
//       600: $light-primary-text,
//       700: $light-primary-text,
//       800: $light-primary-text,
//       900: $light-primary-text,
//       A100: $dark-primary-text,
//       A200: $dark-primary-text,
//       A400: $dark-primary-text,
//       A700: $dark-primary-text,
//     )
//   );
//   $primary: mat.define-palette($palette, 900);
//   $theme: mat.define-light-theme($primary, $accent, $warn);
//   @include mat.all-component-themes($theme);
// }

body {
  background-color: #eee !important;
}
