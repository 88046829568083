@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import 'quill/dist/quill.bubble.css'; 
@import 'quill/dist/quill.snow.css';
@import './colors';
@import './tables';
@import './links';
@import './loading';
@import './ag-grid-overrides';
@import './calendar-overrides';
@import './material-overrides';

body {
  margin: 0;
  font-family: "Work Sans", sans-serif !important;
}

h1 {
  font-size: 26px !important;
}

h2 {
  font-size: 18px !important;
}

h3 {
  font-size: 16px !important;
  font-weight: bold;
}

p {
  margin: 6px 0;
}

md-card {
  margin: 8px;
}

md-card.warn {
  background: #3F51B5;
  color: #ffc107;
  font-size: 18px;
}

md-input {
  width: 100%;
}

a md-icon {
  vertical-align: middle;
  cursor: pointer;
  color: initial;
}

.mat-mdc-dialog-container {
  position: relative;
  max-height: 80vh;
}

tr td .mat-input-wrapper {
  margin: 0.5em 0 0 0 !important;
}

.header-container {
  width: 100%;
  padding: 15px;
}

div.bootstrap-timepicker-widget .glyphicon {
  font: normal normal normal 14px/1 FontAwesome !important;

  &.glyphicon-chevron-down {
    content: "\f078" !important;
  }
}

div.dialog-actions {
  margin-top: 15px;
  text-align: right;
}

span.category-color-block {
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  margin-right: 5px;
  vertical-align: middle;
  content: ' ';

  &.large {
    width: 36px;
    height: 36px;
  }
}

div.mat-tab-body-content {
  padding-top: 14px;
  overflow-x: hidden;
}

div.cal-event-container.dark a {
  color: #fff !important;
}

div.cal-event-container.light a {
  color: $black !important;
}

.min-width-zero {
  min-width: 0;
  overflow: hidden;
}

.min-height-zero {
  min-height: 0;
}

.icon-button {
  mat-icon {
    font-size: 17px;
    padding-right: 8px;
    padding-top: 4px;
  }
}

body {
  min-height: 100vh;
}

.x-auto {
  overflow-x: auto;
}

ngx-stripe-card .StripeElement {
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.48);
  padding-bottom: 1px;

  &.StripeElement--focus {
    border-bottom: 2px solid;
    border-bottom-color: #00695c !important;
    padding-bottom: 0px;
  }

  &.StripeElement--invalid {
    border-bottom: 2px solid;
    border-bottom-color: #e53935 !important;
    padding-bottom: 0px;
  }
}
router-outlet ~ * {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.app-bg {
  background: #f9fbfb;
}

.subnav-bg {
  border-bottom: 1px solid #d5d5d5;
  background: #f4f4f4;
}