table.table {
  width: 100%;

  th, td:not(.with-above-row) {
    padding: 12px;
    border-top: 1px solid $light-grey;
  }

  tr:hover {
    td:not(.with-above-row) {
      background-color: $lighter-green;
      border-top: 1px solid $light-grey;
    }

    span.show-on-hover {
      visibility: visible;
    }
  }

  th {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.4px;
    color: $grey;
    text-transform: uppercase;
    background: #f6f6f6;
    text-align: left;
  }

  .show-on-hover {
    visibility: hidden;
  }
}

@media (max-width: 1025px) {
  .show-on-hover {
    visibility: visible !important;
  }
}
